//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { miceService } from "../../service/miceService";

export default {
  data() {
    return {
      isShow: false,
      selectItem: {},
      videoUrl: "",
      isEmail: true,
      isphone: true,
      invitationInfo: {},
      list: [],
    };
  },
  created() {
    let showOptions = this.iStorage.get("showOptions");
    if (showOptions) {
      this.openselect(this.iStorage.get("selectItem"));
      this.iStorage.set("showOptions", false);
    }

    // 益普生隐藏微信邀约功能
    if (this.iStorage.get("tenant") == "ipsen") {
      this.list = [
        {
          icon: require("../../assets/img/iconyoujian.png"),
          name: "邮件",
        },
        {
          icon: require("../../assets/img/iconshoujiduanxin.png"),
          name: "手机短信",
        },
        {
          icon: require("../../assets/img/iconfuzhixinxi.png"),
          name: "复制信息",
        },
      ];
    } else {
      this.list = [
        {
          icon: require("../../assets/img/iconweixin.png"),
          name: "微信好友",
        },
        {
          icon: require("../../assets/img/iconyoujian.png"),
          name: "邮件",
        },
        {
          icon: require("../../assets/img/iconshoujiduanxin.png"),
          name: "手机短信",
        },
        {
          icon: require("../../assets/img/iconfuzhixinxi.png"),
          name: "复制信息",
        },
      ];
    }
  },
  methods: {
    openselect(item) {
      this.isShow = true;
      this.isEmail = true;
      this.isphone = true;
      this.selectItem = item;
      this.joinMeeting();
      this.getinvitationInfo();
    },
    closeSelectList() {
      this.isShow = false;
      this.$parent.loadData();
    },
    getinvitationInfo() {
      let params = {
        InvitationId: this.selectItem.invitationId,
      };
      miceService.GetinvitationInfo(params).then((res) => {
        if (res.success) {
          this.invitationInfo = res.content.text.replace(/<[^>]+>/g, "");
        }
      });
    },
    goPage(item) {
      let options = {};
      if (["微信好友", "复制信息"].includes(item.name)) {
        this.UpdateInvitationStatus();
      }
      switch (item.name) {
        case "邮件":
          if (!this.selectItem.email) {
            this.$iToast("邮箱信息不完整，请补充信息");
            return false;
          }
          if (this.isEmail) {
            this.isEmail = false;
            this.sendInfo("SendEmailInvitation");
          }
          break;
        case "微信好友":
          options = {
            method: "SMShare.wechatShare",
            postData: {
              share_content: this.invitationInfo,
              share_picture: "",
              share_url: this.videoUrl,
              share_title: "全景会议",
            },
            callback: (item) => {
              console.log(item);
            },
          };
          console.log(options);
          this.iJsBridge.call(options);
          break;
        case "手机短信":
          if (this.isphone) {
            this.isphone = false;
            this.sendInfo("SendSMSInvitation");
          }
          break;
        case "复制信息":
          var input = document.createElement("input");
          input.readOnly = "readonly";
          // input.value = ` 您被邀请出席会议“${this.selectItem.name}”。 会议时间：${this.selectItem.startTime} 点击链接加入：${this.videoUrl} ${this.selectItem.mettingPassWord ? '会议密码：'+ this.selectItem.mettingPassWord : ''}`;
          input.value = this.invitationInfo;
          document.body.appendChild(input);
          input.select();
          input.setSelectionRange(0, input.value.length);
          document.execCommand("Copy");
          document.body.removeChild(input);
          this.$iToast("信息复制成功");
          break;
      }
    },
    joinMeeting() {
      let params = {
        MettingId: this.$route.query.mettingId,
        InvitationId: this.selectItem.invitationId,
        type: "join",
      };
      miceService.QueryUrl(params).then((res) => {
        if (res.success) {
          this.videoUrl = res.content.url;
        }
      });
    },
    async sendInfo(funName) {
      let params = {
        InvitationId: this.selectItem.invitationId,
        MettingId: this.$route.query.mettingId,
      };
      if (
        "roche".indexOf(this.iStorage.get("tenant")) > -1 &&
        funName == "SendEmailInvitation"
      ) {
        params.PlatFormType = 1;
      }
      let res = await miceService[funName](params);
      if (res && res.success) {
        this.$iToast("邀约成功");
        this.isShow = false;
        this.$parent.loadData();
      }
    },
    async UpdateInvitationStatus() {
      let params = {
        InvitationId: this.selectItem.invitationId,
      };
      let res = await miceService.UpdateInvitationStatus(params);
      if (res && res.success) {
        this.closeSelectList();
      }
    },
  },
};
