//
//
//
//
//
//

    export default {
        data() {
            return {
                list:[],
            }
        },
        created() {
        },
        methods:{
            // 打开新界面
            goPage() {
                this.$emit('goPage')
            },
            touchmove(event) {
                let header_h = document.getElementsByClassName('event_header_container')[0].clientHeight;
                event.preventDefault(); //阻止默认事件传播
                var _this = this;
                setTimeout(function () {
                    var _circle = event.targetTouches[0];
                    var _create_event = document.getElementsByClassName("create_event")[0];
                    var _clientWidth = document.documentElement.clientWidth;
                    var _clientHeight = document.documentElement.clientHeight;
                    if (_circle.pageX > 28 && _circle.pageX < _clientWidth - 28) {
                        _create_event.style.left = -28 + _circle.pageX + "px";
                    }
                    if (_circle.pageY > (header_h + 28) && _circle.pageY < _clientHeight - 28) {
                        _create_event.style.top = -28 + _circle.pageY + "px";
                    }
                    _this.iStorage.set("create_event", {
                        create_event_left: -28 + _circle.pageX + "px",
                        create_event_top: -28 + _circle.pageY + "px"
                    });
                }, 0);
            }
        }
    }
