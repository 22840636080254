//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {miceService} from "@/service/miceService";
import addrqButton from "@/components/eventList/addrqButton";
import generalBridge from "@/controllers/generalBridge.js";
import {getCookie} from "tiny-cookie";
export default {
    components: {
        addrqButton,
    },
    data () {
        return {
            eventData: {
                itsExtData: {}
            },
            QueryId: '',
            isAdd: false,
            tableData: [],
            btnList: [],
            meetingStatus: 0,
        }
    },
    created() {
        this.init()
    },
    methods: {
        init () {
            this.eventData = this.iStorage.get('eventData') || JSON.parse(getCookie("eventData"))
            this.QueryId = this.eventData.itsDictData && this.eventData.itsDictData.parameters && this.eventData.itsDictData.parameters.queryId
            this.CheckCanNewItem()
            this.GetQueryData()
        },
        CheckCanNewItem() {
            const eventData = this.eventData;
            let params = {
                MiceId: eventData.proposalId,
            };
            miceService.CheckCanNewItem(params).then((res) => {
                if (res.success) {
                    this.GetQueryObject(res.content.status);
                    this.meetingStatus = res.content.status
                }
            });
        },
        async GetQueryObject(meetingStatus) {
            let params = {
                QueryId: this.QueryId,
                "AdditionalVars":{
                    "status": meetingStatus,
                    "retractFlag": this.eventData.retractFlag ? true : false,
                    "taskStatus": 0
                }
            };
            let res = await miceService.GetQueryObject(params);
            if (res && res.success) {
                if (res.content.clientSettings.headerButtons && res.content.clientSettings.headerButtons.length) {
                    res.content.clientSettings.headerButtons.forEach(element => {
                        this.isAdd = ['add_videoMeeting'].includes(element.actionCode)
                    });
                }
            }
        },
        async GetQueryData() {
            let params = {
                 QueryId: this.QueryId,
                 cluster: "default",
                 parameters: {
                    miceId: this.eventData.proposalId,
                },
            };
            let res = await miceService.GetQueryData(params);
            if (res && res.success) {
                this.tableData = res.content.rows;
                let btn = res.content.uiButtons[0]
                if (btn.length) {
                    this.btnList = btn.filter(ele => !["打开Onlinemeeting"].includes(ele.actionName))
                }
            }
        },
        goPage () {
            if (this.tableData && this.tableData.length) {
                this.$iToast("只能添加一个罗氏线上会议平台订单");
                return false;
            }
            this.$router.push("/addR1Demand")
        },
        clickBtn (btn, data) {
            this[btn.actionCode]( btn, data );
        },
        edit (btn ,data) {
            if (new Date(this.eventData.dtEnd.split('-').join('/')+' 23:59:59') < new Date()) {
                this.$iToast('已过会议时间');
            }
            this.iStorage.set('selectItem', data)
            this.$router.push({
                name: 'addR1Demand',
                query: this.$route.query
            })
        },
        remove (btn ,data) {
            let params = {
                MettingId: data.mettingId,
                IsDelete: true
            };
            this.$iMessage.show({
                title: "提示",
                contentTxt: `删除后无法恢复，确定删除视频会议？`,
                onConfirm: async () => {
                let res = await miceService.SaveItem(params);
                if (res && res.success) {
                    this.$iToast('删除会议成功！');
                    this.GetQueryData();
                }
                }
            })
        },
        cancel (btn ,data) {
            let params = {
                MettingId: data.mettingId,
                IsCancel: true
            };
            this.$iMessage.show({
                title: "提示",
                contentTxt: `取消后无法恢复，确定取消视频会议？`,
                onConfirm: async () => {
                let res = await miceService.SaveItem(params);
                if (res && res.success) {
                    this.$iToast('取消会议成功！');
                    this.GetQueryData();
                }
                }
            })
        },
        openUrl (btn) {
            generalBridge.openNewWebSite(btn.parameters.url)
        },
    }
}
