import {removeCookie, getCookie, getAllCookies} from 'tiny-cookie'
import {iStorage} from '../core'
import {miceService} from "../service/miceService";
import iJsBridge from 'smart-javascript-bridge'
import {Toast} from "mint-ui";
export default class generalBridge {
    static propy () {
        if (['browser','h5', 'native'].includes(getCookie("container") || iStorage.get("container"))) {
            const container = getCookie("container")  || iStorage.get("container");
            switch (container) {
                case "browser":
                    generalBridge._browserBack()
                    break;
                case "h5":
                    generalBridge._h5Back()
                    break;
                case "native":
                default:
                    generalBridge.closeAppWithReload.call(this);
            }
        } else if (iStorage.get("isH5")) {
            generalBridge._h5Back()
        } else {
            generalBridge.closeAppWithReload.call(this);
        }
    }

    static async getUrl () {
        const cacheData = {
            supplierId: iStorage.get("supplierId"),
            userId: iStorage.get("userId"),
            role_codes: iStorage.get("role_codes"),
            directory_id: iStorage.get("directory_id"),
            tenant_code: iStorage.get("tenant_code"),
            token: iStorage.get("token"),
            container: iStorage.get("container"),
            tenant_id: iStorage.get("tenant_id"),
            platform: iStorage.get("platform"),
        };
        const params = {
            Data: cacheData,
            CacheEntryOptions: {
                AbsoluteExpirationRelativeToNow: "08:00:00", //从现在开始计时(时:分:秒）过期
            },
        };
        let res = await miceService.saveCacheData(params)
        let ievent = '';
        if (res && res.success) {
            let miceId = iStorage.get("proposalId");
            let requireId = iStorage.get("eventData").requireId;
            const eventData = iStorage.get('eventData') || JSON.parse(getCookie("eventData"))
            const ieventUrl = iStorage.get("Uris").Uris["webapp-ievent"];
            switch (getCookie('tenant') || getCookie('tenant_code')) {
                case 'msd':
                    ievent =iStorage.get('isAll')?ieventUrl+'/opHome?sessionId='+res.content:
                        `${ieventUrl}/opConfirmPrice?businessId=${miceId}&processId=${miceId}&sessionId=${res.content}`
                    break;
                case 'crossevent':
                    ievent = `${ieventUrl}/eventDetail?miceId=${eventData.miceId}&sessionId=${res.content}`
                    break;
                default:
                    ievent = `${ieventUrl}/eventDetail?requireId=${requireId}&miceId=${miceId}&sessionId=${res.content}&rad=${res.content}`
                    break;
            }
            return ievent;
        }
        return ievent;
    }
    static async _browserBack () {
        let url= await this.getUrl()
        iStorage.remove("proposalId")
        removeCookie('eventData')
        window.open(url);
    }

    static async  _h5Back () {
        let url=await this.getUrl()
        iStorage.remove("proposalId")
        removeCookie('eventData')
        window.open(url, "_self");
    }
    static closeAppWithReload () {
        let IJsBridge = new iJsBridge({ alert: Toast });
        let sendData = JSON.stringify({handle: "reload_event_detail"});
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", sendData);
        } else {
            IJsBridge.call({ method: 'SMGeneral.setResultToNative', postData: sendData });
            IJsBridge.close();
        }
        iStorage.remove("proposalId")
        removeCookie('eventData')
    }
    static openWebAppCate (url) {
        if (['browser','h5', 'native'].includes(getCookie("container"))) {
            const container = getCookie("container");
            switch (container) {
                case "browser":
                    ["webapp-iaccount", "webapp-ihelper"].includes(url) ? this._h5(url) :
                        this._browser(url);
                    break;
                case "h5":
                    this._h5(url);
                    break;
                case "native":
                    this.openNewWebSite(url);
            }
        } else if (iStorage.get("isH5")) {
            this._h5(url);
        } else {
            this.openNewWebSite(url);
        }
    }
    // 浏览器模拟
    static _browser(hostUrl) {
        window.open(hostUrl);
    }
    // h5模式
    static _h5(hostUrl) {
        window.open(hostUrl, "_self");
    }
    static async openNewWebSite (url, _callback = null) {
        let IJsBridge = new iJsBridge({ alert: Toast });
        if (window.flutter_inappwebview) {
            let params = {
                "url": url,
                "postData": {},
                "cookies": getAllCookies()
            }
            let result = await window.flutter_inappwebview.callHandler("SmGeneral.openNewWebSite", JSON.stringify(params));
            _callback(result);
        } else {
            let options = {
                method: !_callback ? "SMGeneral.openNewWebSite" : "SMGeneral.openNewWebSiteForResult",
                postData: {
                    loadUrl: url,
                    nativeLocationHelper: true,
                    cookieData: getAllCookies(),
                },
                callback: _callback,
            };
            if (!_callback) {
                options.postData["browserMode"] = {
                    navBarVisibilty: true,
                    titleTextColor: "#ffffff",
                    navBackgroundColor: "#2D82F0"
                };
            }
            let IJsBridge = new iJsBridge({
                alert: function (e) {
                    console.log(e);
                },
            });
            IJsBridge.call(options);
        }
    }
}
