//
//
//
//
//
//
//
//
//
//
//

    import eventList from '@/components/eventList/eventList.vue'
    import selectList from "@/components/eventList/selectList";
    import generalBridge from "@/controllers/generalBridge.js";
    export default {
        components: {
            eventList,
            selectList
        },
        data () {
            return {
            }
        },
        created () {
        },
        methods: {
            eventDetailGoBack () {
              generalBridge.propy.call(this)
            }
        }
    }
